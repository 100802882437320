.admin-link-store{
margin-top: 30px;
}
.link-store-content{
    width: 100%;
    display: flex;
    gap: 20px;
    margin: 30px 0;
}
.link-store-content-left select{
    border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
    background: var(--primary-color);
    border-radius: 25px;
    width: 200px;
    padding:20px 20px;
    color: aliceblue;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    padding-right: 40px;
}
.link-store-content-right input{
    width: 300px;
    padding:20px 15px;
    border-radius: 25px;
    border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
    background: var(--primary-color);
    color: aliceblue;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
}
.link-store-content-right{
    display: flex;
    gap: 20px;
}
.link-store-content-right button{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 2rem;
    border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
    background: var(--primary-color);
    color: aliceblue;
}
.add-link-button{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 2rem;
    border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
    background: var(--primary-color);
    color: aliceblue;
}