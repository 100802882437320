/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.insight {
  width: 100%;
  background-color: var(--primary-color);
  position: relative;
  min-height: 110vh;
}
h2 {
  margin-left: 20px;
}
.insight-profile {
  text-align: center;
  width: 85%;
  height: 150px;
  border-radius: 20px;
  border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.1));
  background: var(--grey-7, rgba(174, 174, 174, 0.07));
  margin: 0 auto; /* Center the div horizontally */
  margin-top: 30px;
}
.insight-profile-img {
  margin-top: -50px;
}
.insight-profile-img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.insight-profile-content h2,
.insight-profile-content p {
  margin: 2px;
}
.insight-profile-content p {
  color: var(--grey);
}

.insight-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
}
.insight-card-content {
  position: relative;
  width: 125px;
  height: 125px;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.1));
  background: var(--grey-7, rgba(174, 174, 174, 0.07));
}
.info-icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.link-engagement-card-content {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 70px;
  margin: 25px 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  border-radius: 40px;
  border: 1px solid rgba(174, 175, 181, 0.1);
  background: rgba(49, 61, 79, 0.3);
}

.link-engagement-card-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}
.link-engagement-card-left img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.link-engagement-card-right {
  margin-right: 15px;
}
.link-engagement-details p {
  font-size: 12px;
  color: var(--grey);
}
.link-engagement-details p {
  font-size: 10px;
  width: 100px;
  overflow-x: scroll;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.link-engagement-details p::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

@media (min-width: 767px) {
  .link-engagement-details p {
    font-size: 10px;
    width: 160px;
    padding-right: 5px;
    overflow-x: scroll;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap; /* Prevent content from breaking across lines */
  }
  
  .link-engagement-details p::-webkit-scrollbar {
    display: none;
  }
}

