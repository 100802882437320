
.share-popup-container {
  width: 100%;
  height: 250px;
  background: rgb(29,27,41);
  position: fixed;
  z-index: 20;
  left: 0;
  bottom: 0;
  border-radius: 50px 50px 0 0;
  color: #fff;
}
.share-popup-header h3 {
  color: #fff;
  text-align: center;
}
.share-popup-header i {
font-size: 1.5rem;
  position: absolute;
  right: 40px;
  top: 20px;
  cursor: pointer;
 
}
.share-popup-icons {
    padding: 20px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    /* Set a fixed width or max-width to control scrolling behavior */
    max-width: 100%; /* Adjust as needed */
  
    /* Hide the scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
  }

  .share-popup-icons::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
  }
  
  /* Individual share button styles */
  .share-popup-icons > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    margin-right: 10px; /* Adjust spacing between buttons */
  }

.share-popup-copy-link-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.share-popup-copy-link {
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 15px;
    gap: 15px;
    border-radius: 25px;
    border: 1px solid var(--border-color);
  
    color:   #fff;;
}
/* Set the width of the scrollable container */
.share-popup-copy-link p {
  width: 70%;
  overflow-x: scroll;
  color: #fff;
}
.share-popup-copy-link img{
  cursor: pointer;
}
/* Style the scrollbar */
.share-popup-copy-link p::-webkit-scrollbar {
  height: 1px; /* Set the height of the scrollbar */
}

/* Track (the area behind the scrollbar) */
.share-popup-copy-link p::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* You can set any background color you want */
}

/* Handle (the draggable part of the scrollbar) */
.share-popup-copy-link p::-webkit-scrollbar-thumb {
  background-color: #888; /* You can set any color you want */
  border-radius: 1px; /* Set the border radius to make it look round */
}
@media screen and (min-width: 767px) {
    .share-popup-container {
       width: 23%;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
      }
      .share-popup-copy-link {
        width: 80%
    }
}
    
@media screen and (min-width: 1000px) {
    .share-popup-container {
       width: 23%;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
      }
      .share-popup-copy-link {
        width: 300px;
    }
}
    
@media (min-width:767px) and (max-width: 1400px) {
  .share-popup-container {
    width: 50%;
   }
}