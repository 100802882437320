/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.link-store {
  width: 100%;
  background-color: var(--secondary-color);
  position: relative;
  min-height: 100vh;
}
.link-store-container {
  padding: 20px;
}
.popup {
  position: fixed;
  margin-left: -20px;
  bottom: 0;
  width: 100%;
  height: 52vh;
  background-color: var(--primary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px 50px 0 0;
  z-index: 10;
  overflow: scroll;
}
.popup::-webkit-scrollbar {
  width: 0; /* Width of the vertical scrollbar */
  height: 0; /* Height of the horizontal scrollbar */
}

/* Hide the scrollbar thumb (the draggable part) */
.popup::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}

/* Hide the scrollbar track (the non-draggable part) */
.popup::-webkit-scrollbar-track {
  background-color: transparent; /* Make the track transparent */
}

.popup-heading h2 {
  position: absolute;
  top: 20px;
  left: 20px;
  font-weight: 600;
}
.popup input {
  padding: 15px 40px;
  border-radius: 25px;
  border: 1px solid var(--border-color);
  background: transparent;
  color: #fff;
  font-family: "Roboto", sans-serif;
}
.popup-content img {
  width: 100px;
  margin-top: 35px;
}
.popup-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
}
.popup-action button {
  padding: 15px 30px;
  border-radius: 25px;
  background: var(--tertiary-color);
  border: none;
  color: #fff;
  font-weight: bold;
}
.link-store-container-title h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 80px;
}
.link-store-container-title p {
  color: var(--grey);
  font-size: 16px;
}

.link-store-search {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  padding-left: 20px;
  margin-top: 50px;
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(174, 174, 174, 0.1)),
      to(rgba(174, 174, 174, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(174, 174, 174, 0.07)), to(rgba(174, 174, 174, 0.07)));
  background: -o-linear-gradient(
      bottom,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    -o-linear-gradient(bottom, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background: linear-gradient(
      0deg,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    linear-gradient(0deg, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));

  border: var(--border-color);
  border-radius: 25px;
}
.link-store-search label {
  margin-top: 2px;
  cursor: pointer;
  -webkit-transition: 0.5 linear;
  -o-transition: 0.5 linear;
  transition: 0.5 linear;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.link-store-search label:active {
  opacity: 0.5;
}
.link-store-search input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--grey);
  background: transparent;
}
.link-store-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.container-link h2 {
  margin-top: 40px;
}
.item-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.1));
  background: var(--grey-7, rgba(174, 174, 174, 0.07));
  padding: 20px;
  border-radius: 25px;
  margin-top: 20px;
}
.card-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}
.item-card button {
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border-radius: 40px;
  background: var(--black, #12101f);
  padding: 15px 30px;
  cursor: pointer;
  -webkit-transition: 0.5 linear;
  -o-transition: 0.5 linear;
  transition: 0.5 linear;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.item-card button:hover {
  opacity: 0.8;
}
.item-card button:active {
  scale: 0.9;
}

.container-link.home .item-card {
  margin: 10px 20px 0 20px;
}

/* CHECKBOX CODE */

.switch {
  --button-width: 3.3em;
  --button-height: 1.8em;
  --toggle-diameter: 1.1em;
  --button-toggle-offset: calc(
    (var(--button-height) - var(--toggle-diameter)) / 2
  );
  --toggle-shadow-offset: 10px;
  --toggle-wider: 3em;
  --color-grey: rgba(174, 174, 174, 0.1);
  --color-green: var(--tertiary-color);
}

.slider {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.slider::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: #fff;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  -webkit-transform: translateX(var(--button-toggle-offset));
  -ms-transform: translateX(var(--button-toggle-offset));
  transform: translateX(var(--button-toggle-offset));
  -webkit-box-shadow: var(--toggle-shadow-offset) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  box-shadow: var(--toggle-shadow-offset) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked + .slider {
  background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked + .slider::after {
  -webkit-transform: translateX(
    calc(
      var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)
    )
  );
  -ms-transform: translateX(
    calc(
      var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)
    )
  );
  transform: translateX(
    calc(
      var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)
    )
  );
  -webkit-box-shadow: calc(var(--toggle-shadow-offset) * -1) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"]:active + .slider::after {
  width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active + .slider::after {
  -webkit-transform: translateX(
    calc(
      var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)
    )
  );
  -ms-transform: translateX(
    calc(
      var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)
    )
  );
  transform: translateX(
    calc(
      var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)
    )
  );
}

.input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 300px; /* Set your desired width */
}

.input-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
::-webkit-input-placeholder {
  color: #fff;
}
::-moz-placeholder {
  color: #fff;
}
:-ms-input-placeholder {
  color: #fff;
}
::-ms-input-placeholder {
  color: #fff;
}
::placeholder {
  color: #fff;
}
.input-field {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button.plus-button,
button.close-button {
  padding: 0px;
  position: absolute;
  right: 5px;
  font-size: 2.3rem;
  font-weight: normal;
  top: -7px;
  background: none;
  border-radius: 100%;
  color: #fff;
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 8px 12px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; /* Transition for the rotation */
  -webkit-tap-highlight-color: transparent;
}

button.plus-button {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); /* Initial rotation angle for "add" */
}

button.close-button {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); /* Initial rotation angle for "close" */
}

.number-list-popup {
  max-height: 120px;
  overflow-y: auto;
  position: absolute;
  top: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 250px;

  border-radius: 10px;
  padding: 10px;

  z-index: 10;
  color: #000;
}
.number-list-item.active {
  background: #fff;

  padding: 10px;
}
.number-list-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.number-list-popup ul li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.number-list-popup ul li div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.edit-number-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}
.fa-trash {
  color: rgb(255, 72, 72);
}

/* phone input */
.input-wrapper .react-tel-input .form-control {
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  height: 45px;
  width: 300px;
}

.input-wrapper .react-tel-input .flag-dropdown {
  color: #000;
  background-color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: 25px 0 0 25px;
}
.input-wrapper .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: 25px 0 0 25px;
}
.input-wrapper .react-tel-input .selected-flag {
  background-color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: 25px 0 0 25px;
}
.input-wrapper .react-tel-input .country-list {
  background-color: var(--secondary-color);
  color: var(--grey);
}
.input-wrapper .react-tel-input .country-list li.country:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.input-wrapper .react-tel-input .country-list li.country.highlight {
  background-color: var(--primary-color);
  color: #fff;
}
.container-link.home{
  margin-bottom: 80px;
}
.done-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 30px;
}

.done-button {
  background: var(--tertiary-color);
  border: none;
  border-radius: 25px;
  padding: 10px 90px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: 0.5 linear;
  -o-transition: 0.5 linear;
  transition: 0.5 linear;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (min-width: 768px) {
  .link-store-search {
    width: 80%;
  }
  .popup {
    width: 23%;
  }
  button.close-button {
    right: 20px;
  }
}
@media (min-width: 767px) and (max-width: 1400px) {
  .popup {
    width: 50%;
  }
}
