

.preview {
  background-color: var(--primary-color);
  position: relative;
  min-height: 100vh;
  overflow-y: scroll;
}
.preview::-webkit-scrollbar {
    width: 0.1rem; /* Adjust as needed */
    background-color: transparent;
}
.preview::-webkit-scrollbar-thumb{
    background-color: transparent;
}
a {
  text-decoration: none;
  color: white;
}
.share-img {
  cursor: pointer;
}
.preview-header {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  width: 100%;
  height: 272px;
}
.preview-profile {
  position:relative;
  top: 30%;
  left: 5%;
  width: 90%;
  height: max-content;
  border: 1px solid #aeaeae33;
  border-radius: 10px;
  border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
  background: var(--bg-1, #262835);
  z-index: 1;
  border-radius: 10px;
  padding-bottom: 30px;
  margin-top: -80px;
}

.preview-profile .profile-img {
  position: absolute;
  top: -1%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.preview-profile .logo-img {
  position: absolute;
  top: 17%;
  left: 65%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.preview-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-top: 70px;
  margin-left: 15px;
  font-size: 16px;
}
h1 {
  margin-top: 10px;
  font-size: 22px;
}
.preview-role h3 {
  margin: 5px;
  font-size: 18px;
  margin-left: 20px;
  text-align: center;
}
.preview-role span {
  color: var(--tertiary-color);
}
.preview-company-name {
  text-align: center;
}
.preview-company-name h4 {
  color: var(--tertiary-color);
}
.preview-company-name-bio {
  padding: 8px 25px;
}
.preview-company-name p {
  margin: 5px;
  color: var(--grey);
  width: 100%;
  overflow: hidden;

}
.preview-contact-me {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-top: 10px;
}
.preview-contact-me p {
  margin: 0;
}
.preview-contact-me button {
  background: var(--primary-color);
  padding: 10px 30px;
  color: #fff;
  border: 1px solid #aeaeae33;
  border-radius: 25px;
  font-family: "Roboto", sans-serif;
}

.preview-social-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns in one row */
  gap: 14px;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 35px;
}
.preview-social-link {
  width: 100%;
  max-width: 100%;
  overflow: hidden; /* Hide overflow content */
  word-wrap: break-word; /* Wrap long words */
  white-space: normal; /* Allow normal white space */
}
.social-icon img {
  width: 60px;
}
.preview-social-links {
  cursor: pointer;
}

/* contact form */
.contact-form-container {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  z-index: 1000;
  background: var(--primary-color);
}
.contact-logo {
  width: 100%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact-form-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 20px;
  width: 100%;
  height: 30px;
  color: var(--grey);
}
.contact-form-header img {
  width: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.contact-form {
  border-radius: 10px;
  border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.1));
  background: var(--grey-7, rgba(174, 174, 174, 0.07));
  padding: 20px;
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact-form input {
  width: 90%;
  height: 44px;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  border-bottom: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
  background-color: rgb(47 49 61) !important;
  font-family: "Roboto", sans-serif;
  color: var(--grey);
}

.contact-form input:focus {
  background: rgb(47 49 61) !important;
}

.contact-form input::-webkit-input-placeholder {
  color: var(--grey);
}

.contact-form input::-moz-placeholder {
  color: var(--grey);
}

.contact-form input:-ms-input-placeholder {
  color: var(--grey);
}

.contact-form input::-ms-input-placeholder {
  color: var(--grey);
}

.contact-form input::placeholder {
  color: var(--grey);
}

.connect-button {
  width: 90%;
  padding: 10px 30px;

  margin: 16px 0;
  cursor: pointer;
  border-radius: 40px;
  background: var(--tertiary-color, #00d0ff);
  border: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.preview-bottom{
  width: 100%;
  height: 30px;
  background: var(--primary-color);
  display:flex ;
  justify-content: center;
  align-items: center;
  margin:30px 0;
}
.preview-bottom p{
  font-size:12px;
  font-weight: 700;
}
.preview-bottom img{
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  align-self: flex-end;
}

@media screen and (max-width:1200px) {
    .contact-form-container {
        width: 100%;
        height: 110%;
      }
}
@media (min-width:1092px) and (max-width:1400px) {
  .preview-social-links {
    grid-template-columns: repeat(3, 1fr); /* 4 columns in one row */
  }
}
@media (max-width:330px) {
  .preview-social-links {

    grid-template-columns: repeat(2, 1fr); /* 4 columns in one row */
  }
}
/* animation */
.social-icon:hover img {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
	        animation: pulsate-bck 0.5s ease-in-out infinite both;
}
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}