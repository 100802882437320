/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.top-nav {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px;
}
.top-nav-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}
.top-nav-left img{
  cursor: pointer;
}
.top-nav-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--grey);
}
.top-nav-right i {
  font-size: 20px;
  cursor: pointer;
}
.bottom-navbar {
  width: 100%;
  background: var(--primary-color);
  position: fixed;
  bottom: 0;
  border-top: 1px solid var(--border-color);
}
a {
  text-decoration: none;
}
a.active p,
a.active path {
  stroke-opacity: 1;
}
.bottom-navbar__item {
  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}

.bottom-navbar__item-icon {
  text-align: center;
  cursor: pointer;
}
.bottom-navbar__item-icon p {
  color: var(--grey);
  font-size: 12px;
  font-weight: 400;
}

/* NavBar */
.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  right: -100%;
  height: 100px;
  background-color: var(--primary-color);
  z-index: 1000;
  -webkit-transition: right 0.3s ease;
  -o-transition: right 0.3s ease;
  transition: right 0.3s ease; /* Transition for the 'right' property */
}

.navbar-container.active {
  right: 0; /* When active, move to the left (0) */
}
.navbar-container nav {
  margin-top: 160px;
}
.navbar-container i {

  font-size: 2rem;
  color: var(--grey);
  cursor: pointer;
}
.navbar-container nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  gap: 40px;
  margin-right: 30px;
}
/* Style for normal state */
.navbar-container nav ul li a {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--primary-color);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear; /* Add transition property for color change */
}

/* Style for hover state */
.navbar-container nav ul li a:hover {
  opacity: 0.7;
}

/* Style for active state */
.navbar-container nav ul li a.active {
  color: #fff; /* Change to the color you want for active links */
}

li {
  list-style: none;
}
@media screen and (min-width: 768px) {
  .bottom-navbar {
    width: 23%;
    background: var(--primary-color);
    border-top: 1px solid var(--border-color);
  }
  .navbar-container {
    position: fixed;
    top: 0;
    width: 23%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: none;
  }
  .navbar-container.active {
    display: block;
  }
  .navbar-container nav ul li a {
    font-size: 1.5rem;
  }
}
@media (min-width:767px) and (max-width: 1400px) {
  .navbar-container {
    width: 50%;
  }
}