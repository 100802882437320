

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* In your CSS file, e.g., app.css */
@import "~@fortawesome/fontawesome-free/css/all.css";

:root {
  --primary-color: #262835;
  --secondary-color: #12101f;
  --tertiary-color: #00D0FF;
  --grey: #aeaeae;
  --border-color: #cacaca83;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
/* Hide scrollbar for all elements */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Hide scrollbar for Webkit based browsers (Safari and Chrome) */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
  background-color:#fff;
}

body::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

body {
  -webkit-tap-highlight-color: transparent; /* Webkit browsers */
}

/* Remove blue highlight color on text selection */
::selection {
  background-color: transparent;
}

