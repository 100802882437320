

.settings {
  width: 100%;
  background-color: var(--primary-color);
  position: relative;
  min-height: 100vh;
}

.my-account {
  margin: 20px 10px;
  padding: 10px 0 10px 20px;
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(174, 174, 174, 0.1)),
      to(rgba(174, 174, 174, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(174, 174, 174, 0.07)), to(rgba(174, 174, 174, 0.07)));
  background: -o-linear-gradient(
      bottom,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    -o-linear-gradient(bottom, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background: linear-gradient(
      0deg,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    linear-gradient(0deg, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  border-radius: 25px;
  border: 1px solid #aeaeae1a;
  height: 120px;
}
.my-account h4 {
  margin: 15px 0;
}
.my-account-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-bottom: 100px;
}
.settings-items {
  margin: 50px 0;
}
.settings-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px 10px;
  padding: 10px 0 10px 20px;
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(174, 174, 174, 0.1)),
      to(rgba(174, 174, 174, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(174, 174, 174, 0.07)), to(rgba(174, 174, 174, 0.07)));
  background: -o-linear-gradient(
      bottom,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    -o-linear-gradient(bottom, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background: linear-gradient(
      0deg,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    linear-gradient(0deg, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  border-radius: 25px;
  border: 1px solid #aeaeae1a;
  cursor: pointer;
}
.settings-card p {
  margin-right: 20px;
  font-size: 12px;
}

.settings-card.email {
  overflow: hidden;
}
.my-account-container img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.modal-buttons{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
}
.modal-buttons button {
    width: 150px;
    height: 50px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: red;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
            box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    background: #e62222;
   }
   
   .modal-buttons button,.modal-buttons button span {
    -webkit-transition: 200ms;
    -o-transition: 200ms;
    transition: 200ms;
   }
   
   .modal-buttons button .text {
    -webkit-transform: translateX(35px);
        -ms-transform: translateX(35px);
            transform: translateX(35px);
    color: white;
    font-weight: bold;
   }
   
   .modal-buttons button .icon {
    position: absolute;
    border-left: 1px solid #c41b1b;
    -webkit-transform: translateX(110px);
        -ms-transform: translateX(110px);
            transform: translateX(110px);
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
   }
   
   .modal-buttons button svg {
    width: 15px;
    fill: #eee;
   }
   
   .modal-buttons button:hover {
    background: #ff3636;
   }
   
   .modal-buttons button:hover .text {
    color: transparent;
   }
   
   .modal-buttons button:hover .icon {
    width: 150px;
    border-left: none;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
   }
   
   .modal-buttons button:focus {
    outline: none;
   }
   
   .modal-buttons  button:active .icon svg {
    -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
            transform: scale(0.8);
   }
   .modal-buttons .cancel-btn{
    background-color: rgb(0, 214, 154);
   }
   .modal-buttons .cancel-btn:hover {
    background-color: rgb(0, 214, 154);
   }
   .modal-buttons .cancel-btn .icon {

    border-left: 1px solid #1bc4b6;
   }