/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.mails-container{
  width: 100%;
  background-color: var(--primary-color);
  position: relative;
  min-height: 100vh;
  margin-bottom: 50px;
}
.mails-container::-webkit-scrollbar {
  width: 0.1rem; /* Adjust as needed */
  background-color: transparent;
}

.mails-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.mails-header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.clear-all-mails{
  background: rgb(255, 18, 18);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;
  font-family: "Roboto", sans-serif;
  margin-right: 40px;
}
.fa-trash{
  cursor: pointer;
}
.mails-search {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  padding: 10px;
  padding-left: 20px;
  margin-left: 10px;
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(174, 174, 174, 0.1)),
      to(rgba(174, 174, 174, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(174, 174, 174, 0.07)), to(rgba(174, 174, 174, 0.07)));
  background: -o-linear-gradient(
      bottom,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    -o-linear-gradient(bottom, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background: linear-gradient(
      0deg,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    linear-gradient(0deg, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));

  border: var(--border-color);
  border-radius: 25px;
}
.mails-search label {
  margin-top: 2px;
  cursor: pointer;
  -webkit-transition: 0.5 linear;
  -o-transition: 0.5 linear;
  transition: 0.5 linear;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mails-search label:active {
  opacity: 0.5;
}
.mails-search input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--grey);
  background: transparent;
}
.mails-list{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.mails-list-item{
  width: 80%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.10));
  background: var(--grey-7, rgba(174, 174, 174, 0.07));
  cursor: pointer;
}
.mails-list-item p{
  font-size: 12px;
  font-weight: 400;
  color: var(--grey);
}
.mail-popup-container{
  position: fixed;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  height: 60vh;
  z-index: 100;
  border-radius: 10px;
border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.10));
background:rgb(47,49,61);
}
.mail-popup-header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 30px;
}

.mail-popup-header img{
  cursor: pointer;
  margin-right: 20px;
  margin-top:10px;
}
.mail-popup-fields{
padding: 0 20px;

}
.popup-field{
border-bottom: 1px solid var(--grey-10, rgba(174, 174, 174, 0.10));
font-weight: 400;
}
.mails-list-left{
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (min-width: 767px) {

  .mail-popup-container{
    width: 23%;
  }
}
@media (min-width:767px) and (max-width: 1400px) {
  .mail-popup-container{
    width: 50%;
  }
}