
h1 {
  margin: 0;
}
::placeholder{
  color: var(--grey);
}
.sign-in {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign-in p {
  color:var(--grey);
}
.sign-in-container {
  margin-top: 80px;
  position: relative;
}

.phone-input {
  color: grey;
  margin-top: 60px;
}
.sign-in .react-tel-input .form-control {
  color: var(--grey);
  background: var(--primary-color);
  border: 0.5px solid var(--border-color);
  border-radius: 25px;
  line-height: 25px;
  height: 50px;
  width: 280px;
  outline: none;
}
.react-tel-input input.form-control::placeholder{
  color: var(--grey);
}
.sign-in .phone-input .react-tel-input {
  background-color: var(--primary-color);
  border: none;
  border-right: none;
  border-radius: 25px;
}
.sign-in .react-tel-input .flag-dropdown,
.sign-in .react-tel-input .flag-dropdown.open .selected-flag,
.phone-input .react-tel-input > div:nth-of-type(2) > div.selected-flag:hover,
.phone-input .react-tel-input > div:nth-of-type(2) > div.selected-flag:active {
  background-color: var(--primary-color);
  border: 0.5px solid var(--border-color);
  border-right: none;
  border-radius: 25px 0 0 25px;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: var(--primary-color);
  border: 0.5px solid var(--border-color);
  border-right: none;
  border-radius: 25px 0 0 25px;
}
.sign-in .react-tel-input .flag-dropdown.open {
  z-index: 1;
  background-color: var(--primary-color);
  border: none;
}

.sign-in-button {
  margin-top: 60px;
  text-align: center;
}
.sign-in-button button{
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  background: var(--tertiary-color);
  color: #fff;
  border: none;
  height: 40px;
  width: 280px;
  border-radius: 25px;
  -webkit-box-shadow: 0px 1px 2px #2B3044,
  0px 4px 16px #2B3044;
          box-shadow: 0px 1px 2px #2B3044,
  0px 4px 16px #2B3044;
-webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
-webkit-transform: scale(var(--s, 1)) perspective(600px)
  rotateX(var(--rx, 0deg)) rotateY(var(--ry, 0deg));
        transform: scale(var(--s, 1)) perspective(600px)
  rotateX(var(--rx, 0deg)) rotateY(var(--ry, 0deg));
-webkit-perspective: 600px;
        perspective: 600px;
-webkit-transition: -webkit-transform 0.1s;
transition: -webkit-transform 0.1s;
-o-transition: transform 0.1s;
transition: transform 0.1s;
transition: transform 0.1s, -webkit-transform 0.1s;
}
.sign-in-button button:hover{
opacity: .8;
}
.sign-in-button button:active{
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.93);
      -ms-transform: scale(0.93);
          transform: scale(0.93);
}

.separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 40px 0;
}

.line {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 1px;
  background-color: var(--tertiary-color);
}

.or {
  margin: 0 10px;
  font-weight: 500;
  color: var(--grey); 
}
.sign-in-buttons{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign-in-buttons img{
  width: 35px;
  height: 35px;
  
}
.sign-in-with-google, .sign-in-with-facebook {
  cursor: pointer;
  padding: 6px;
  padding-bottom: 2px;
  border: 1px solid var(--border-color);
  border-radius: 100%;
}

.sign-in-footer{
  margin-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign-in-footer p{
  color: var(--grey);
  font-size: 12px;
  font-weight:400;
}
.sign-in-footer h6{
margin-top:5px;
}

.recaptcha-container{
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign.back-button{
  position: absolute;
  top: -30px;
  left: 5px;
  cursor: pointer;
}
.logo-image{
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-align: center;
     -ms-flex-align: center;
         align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.sign-in .react-tel-input .country-list {
  background-color: var(--secondary-color);
  color: var(--grey);

}
.react-tel-input .country-list::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

/* Style of the scrollbar thumb (the draggable part) */
.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background-color:var(--tertiary-color); /* Color of the scrollbar thumb */
  border-radius: 3px; /* Rounded corners for the thumb */
}

/* Style of the scrollbar track (the non-draggable part) */
.react-tel-input .country-list::-webkit-scrollbar-track {
  background-color:var(--secondary-color); /* Color of the scrollbar track */
}
.react-tel-input .country-list li.country:hover{
  background-color: var(--primary-color);
  color: #fff;
}

.react-tel-input .country-list li.country.highlight{
  background-color:var(--primary-color);
  color: #fff;
}

.verify-h2{
  text-align: center;
}
.sign-in-footer-content{
  display:flex;
}