/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.home {
  width: 100%;
  /* min-height: 65vh;
  background: var(--secondary-color); */
  /*
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1); */
}
.app.home-container{
  background: var(--secondary-color);
}
.home__tabs {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.home__tabs .home-tab-container {
  width: 80%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
  background-color: var(--primary-color);
}
.home-tab-container .personal,
.home-tab-container .business {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
  cursor: pointer;
  /* Set the initial background color */
  -webkit-transition: background 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: background 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: background 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); /* Apply the transition to the background property */
}

/* Apply different background colors for each tab when active */
.home-tab-container .personal.active {
  background-color: var(--tertiary-color);
}

.home-tab-container .business.active {
  background-color: var(--tertiary-color);
}

/* USER CARD */
.user-card {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 40px;
}
.user-card__image img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.user-card-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  gap: 20px;
  margin-left: 20px;
}
.user-card-right {
  margin-right: 20px;
  cursor: pointer;
}
h4 {
  margin: 0;
}

.add-button {
  position: fixed;
  bottom:30px;
  right: 40px;
  cursor: pointer;
  z-index: 10;
}
.home-link-popup-container {
  position: fixed;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  height: 30vh;
  background-color: rgb(29,27,41);

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 100;
  border-radius: 50px 50px 0 0;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
}
.home-link-popup-content p {
  cursor: pointer;
}
.home-link-popup-content.delete {
  text-align: center;
  color: rgb(255, 65, 65);
  border: none;
  border-top: 0.8px solid #cccccca0;
  border-bottom: 0.8px solid #cccccca0;
  width: 100%;
}
.home-link-popup-content.edit {
  color: rgb(34, 115, 255);
}
.edit-input-home {
  width: 200px;
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-color);
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  color: #fff;
}
.home-link-popup-container button {
  padding: 15px 60px;
  border-radius: 25px;
  background: var(--tertiary-color);
  border: none;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
}
.item-card img {
  cursor: pointer;
  width:46px;
}
.fa-grip-vertical {
  cursor: pointer;
  color: var(--grey);
}
.card-left {
  cursor: pointer;
}
/* BUSINESS CARD */
.business-section {
  height: 100%;
}
.business-cover-image {
  width: 90%;
  height: 200px;
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(174, 174, 174, 0.1)),
      to(rgba(174, 174, 174, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(174, 174, 174, 0.07)), to(rgba(174, 174, 174, 0.07)));
  background: -o-linear-gradient(
      bottom,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    -o-linear-gradient(bottom, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background: linear-gradient(
      0deg,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    linear-gradient(0deg, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background-position: center;
  background-size: cover;
  border: 1px solid #aeaeae1a;
  position: relative;
  margin: 20px auto;
  border-radius: 25px;
}
.business-cover-image label {
  background-color: #fff;
  color: var(--primary-color);
  position: absolute;
  top: 25px;
  right: 15px;
  border: none;
  border-radius: 25px;
  padding: 1px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
}
#cover-image,
#profile-image {
  visibility: hidden;
}
.business-details {
  height: 50vh;
  background: var(--primary-color);
  position: relative;
  border-radius: 50px 50px 0 0;
  margin-top: -50px;
  padding-bottom: 86px;

}

.business-profile-image {
  position: absolute;
  top: -50px;
  left: 35%;
}
.business-profile-image label {
  position: relative;
}
.business-profile-image label .logo {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: var(--grey);
  -o-object-fit: cover;
     object-fit: cover;
}
.business-profile-image label .camera-icon {
  position: absolute;
  top: -20px;
  right: 0px;
}
h5 {
  margin: 0;
}
.business-form {
  padding-top: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  gap: 20px;
}
.business-form input {
  padding: 15px 60px;
  color: #fff;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  font-family: "Roboto", sans-serif;
  padding-left: 20px;
}
.business-form input:-webkit-autofill,
.business-form textarea:-webkit-autofill,
.business-form select:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px inset var(--primary-color, transparent) !important;
}

/* Set text color for all autofill input fields */
.business-form input:-webkit-autofill {
  -webkit-text-fill-color:  #fff !important;
  color: #fff !important;
  caret-color: var(--grey, initial) !important;
}

.business-form button {
  padding: 15px 100px;
  color: #fff;
  background: var(--tertiary-color);
  border: none;
  border-radius: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
          box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
          transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
  -webkit-perspective: 600px;
          perspective: 600px;
  -webkit-transition: -webkit-transform 0.1s;
  transition: -webkit-transform 0.1s;
  -o-transition: transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}
.business-form button:hover {
  opacity: 0.8;
}
.business-form button:active {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.93);
      -ms-transform: scale(0.93);
          transform: scale(0.93);
}
.home-list-item.item-card {
  border-radius: 10px;
  
}
.business-crop-container{
  width: 100%;
height: 100%;
min-height: 100vh;
background-color: var(--primary-color);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding-top: 100px;
}
.business-crop-container div{
  background-color: var(--primary-color) !important;
  /* border: 1px solid var(--border-color) !important; */
  }
@media screen and (min-width: 767px) {
  .add-button {
    width: 23%;
    position: fixed;
    bottom: 60px;
    left: 39%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .add-button img{
    width: 60px;
    margin-right: 30px;
  }
  .home-link-popup-container {
    width: 23%;
  }
  .business-crop-container{
    width: 23%;
  }
}

@media (min-width:767px) and (max-width: 1400px) {
  .home-link-popup-container {
    width: 50%;
  }
  .business-crop-container{
    width: 50%;
  }
  .add-button {
    width: 35%;
    position: fixed;
    bottom: 20px;
    left: 39%;

  }
}