/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.loading-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
width: 100%;
  background-color: var(--primary-color);
  height: 100vh;
}
.spinner {
width: 56px;
height: 56px;
border: 20px var(--primary-color) double;
background-color: var(--tertiary-color);
border-left-style: solid;
border-radius: 100%;
-webkit-animation: spinner 0.75s infinite linear;
        animation: spinner 0.75s infinite linear;
}

@-webkit-keyframes spinner {
to {
 -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
}
}

@keyframes spinner {
to {
 -webkit-transform: rotate(360deg);
         transform: rotate(360deg);
}
}
