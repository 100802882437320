.profile-creation {
  width: 100%;
  position: relative;
  min-height: 115vh;
  background: var(--secondary-color);
}
.profile-creation-container {
  position: relative;
  top: 0;
  left: 0;
}
.profile-creation-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.profile-creation-header label {
  background-size: cover;
}
.file-upload {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  cursor: pointer;
  position: absolute;
  top: 15%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  color: var(--secondary-color);
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  background-size: contain;

  background-position: center;
  /* move background y direction */
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 80px 1px var(--tertiary-color);
  box-shadow: 0 0 80px 1px var(--tertiary-color);
}
#file-input {
  display: none;
}
.file-upload img {
  width: 35px;
}
.back-button {
  position: absolute;
  top: 30px;
  left: 20px;
}

.profile-creation-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  padding: 0 30px;
  margin-top: 250px;
}
.profile-creation-body input {
  height: 50px;
}
.profile-creation-body input,
.profile-creation-body textarea {
  color: #fff;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 25px;
  font-family: "Roboto", sans-serif;
  padding-left: 20px;
}

/* Reset autofill styles */
.profile-creation-body input:-webkit-autofill,
.profile-creation-body textarea:-webkit-autofill,
.profile-creation-body select:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px inset var(--secondary-color, transparent) !important;
}
/* .profile-creation .react-tel-input input:-webkit-autofill{
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px inset var(--primary-color transparent) !important;
} */
/* Set text color for all autofill input fields */
.profile-creation-body input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  color: #fff !important;
  caret-color: var(--grey, initial) !important;
}

.profile-creation-body textarea {
  padding-top: 20px;
}
.phone-input.profile {
  color: grey;
  margin-top: 0;
}

.profile-creation .react-tel-input .form-control {
  color: var(--grey);
  background-color: var(--secondary-color);
  border: 0.5px solid var(--border-color);
  border-radius: 25px;
  line-height: 25px;
  height: 50px;
  width: 100%;
  outline: none;
}
.profile-creation .phone-input.active .react-tel-input .form-control {
  background-color: var(--primary-color);
}

.profile-creation .phone-input .react-tel-input {
  background-color: var(--primary-color);
  border: none;
  border-right: none;
  border-radius: 25px;
}
.profile-creation .react-tel-input .flag-dropdown,
.profile-creation .react-tel-input .flag-dropdown.open .selected-flag,
.phone-input .react-tel-input > div:nth-of-type(2) > div.selected-flag:hover,
.phone-input .react-tel-input > div:nth-of-type(2) > div.selected-flag:active {
  background-color: var(--secondary-color);
  border: 0.5px solid var(--border-color);
  border-right: none;
  border-radius: 25px 0 0 25px;
}
.profile-creation .phone-input.active .react-tel-input .flag-dropdown,
.profile-creation
  .phone-input.active
  .react-tel-input
  .flag-dropdown.open
  .selected-flag,
.phone-input.active
  .react-tel-input
  > div:nth-of-type(2)
  > div.selected-flag:hover,
.phone-input.active
  .react-tel-input
  > div:nth-of-type(2)
  > div.selected-flag:active {
  background-color: var(--primary-color);
}
.profile-creation .react-tel-input .flag-dropdown.open {
  z-index: 10;
  background-color: var(--primary-color);
  border: none;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 12px;
}

.profile-creation .react-tel-input .country-list {
  background-color: var(--primary-color);
  color: var(--grey);
}
.profile-creation .react-tel-input .country-list li.country:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.profile-creation .react-tel-input .country-list li.country.highlight {
  background-color: var(--tertiary-color);
  color: #fff;
}
.save-button button {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  background: var(--tertiary-color);
  color: #fff;
  border: none;
  height: 50px;
  width: 100%;
  border-radius: 25px;
  -webkit-box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
  box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scale(var(--s, 1)) perspective(600px)
    rotateX(var(--rx, 0deg)) rotateY(var(--ry, 0deg));
  transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
  -webkit-perspective: 600px;
  perspective: 600px;
  -webkit-transition: -webkit-transform 0.1s;
  transition: -webkit-transform 0.1s;
  -o-transition: transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}
.save-button button:hover {
  opacity: 0.8;
}
.save-button button:active {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.93);
  -ms-transform: scale(0.93);
  transform: scale(0.93);
}

.error {
  font-size: 12px;
  color: rgb(255, 86, 86);
  text-align: center;
}
.profile-creation-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 2px;
}
.profile-creation-content.active input {
  background-color: var(--primary-color);
}
.crop-container {
  width: 100%;
  height: 100%;
  min-height: 120vh;
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding-top: 100px;
}
.crop-container div {
  background-color: var(--primary-color) !important;
  /* border: 1px solid var(--border-color) !important; */
}
/* CSS */
.crop-buttons {
  display: flex;
  align-items: center;
  gap: 50px;
}
.crop-done {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.crop-done:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.crop-done:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.crop-done:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

/* CSS */
.crop-cancel {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.crop-cancel:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.crop-cancel:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.crop-cancel:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}
