.container.admin {
  all: unset;
}
.admin-dashboard {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
}
.side-nav {
  background-color: var(--secondary-color);
  color: white;
}
.side-nav ul {
  list-style: none;
  padding: 0;
  margin-left: 50px;
}
.side-nav ul li {
  margin: 30px 0;
  cursor: pointer;
}
.main {
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
}
.main-top button {
  background-color: var(--tertiary-color);
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 25px;
  border: 1px solid rgba(174, 174, 174, 0.2);

  padding: 10px 40px;
}
.main-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-buttons {
  margin-top: 50px;
  border-bottom: 1px solid var(--grey);
}
.main-buttons button {
  background-color: var(--primary-color);
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));

  padding: 10px 40px;
}
.main-buttons button:hover {
  background-color: var(--tertiary-color);
}
.admin-profile {
  margin-top: 50px;
  padding: 20px;
  border-bottom: 1px solid var(--grey);
}
.profile-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.profile-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.profile-field input {
  width: 200px;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
  background: var(--primary-color);
  color: white;
}
.profile-bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.profile-textarea {
  display: flex;
  gap: 20px;
}
.profile-textarea textarea {
  width: 100%;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
  background: var(--primary-color);
  color: white;
}
.profile-image {
  display: flex;

  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.profile-image input {
  width: 100px;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
  background: var(--primary-color);
  color: white;
}
.business-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.business-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.business-field input {
  width: 200px;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid var(--grey-20, rgba(174, 174, 174, 0.2));
  background: var(--primary-color);
  color: white;
}
.side-nav-top{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.side-nav-top img{
  width: 150px;

  border-radius: 50%;
  object-fit: cover;
}
.page-button{
  padding:10px 20px;
  background-color: var(--tertiary-color);
  color: white;
  border-radius: 25px;
  border: 1px solid rgba(174, 174, 174, 0.2);
  cursor: pointer;

}
.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .admin-dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: 120px 1fr;
  }
  .side-nav ul {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin: 0 20px;
  }
  .side-nav-top img{
    width: 50px;
  }
  .side-nav-top h1{
    display: none;
  }
  .side-nav-top{
    all: unset;
    display: flex;
    align-items: center;
    margin: 0 20px;
    justify-content: flex-start;
    gap:20px;
  }
}


