
h1 {
  margin: 0;
}

.otp-verify {
  width: 100%;
  min-height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--primary-color);
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.back-button {
  position: absolute;
  top: 30px;
  left: 20px;
  cursor: pointer;
}
.otp-verify-container {
  margin-top: 30px;
  width: 100%;
  background: var(--primary-color);
}
.otp-verify-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.otp-verify-body {
  margin-top: 20px;
  margin-left: 20px;
}
.otp-verify-body p {
  width: 70%;
  color: var(--grey);
}
.otp-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  margin-left: -10px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.otp-input input {
  width: 25px;
  margin-left: 5px;
  height: 50px;
  background-color: var(--primary-color);
  border: 1px solid var(--tertiary-color);
  border-radius: 10px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding-left: 20px;
}
.otp-input input::-webkit-outer-spin-button,
.otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-verify-button {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.otp-verify-button button {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  background: var(--tertiary-color);
  color: #fff;
  border: none;
  height: 40px;
  width: 280px;
  border-radius: 25px;
  -webkit-box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
          box-shadow: 0px 1px 2px #2b3044, 0px 4px 16px #2b3044;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
          transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
  -webkit-perspective: 600px;
          perspective: 600px;
  -webkit-transition: -webkit-transform 0.1s;
  transition: -webkit-transform 0.1s;
  -o-transition: transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}
.otp-verify-button button:active {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.93);
      -ms-transform: scale(0.93);
          transform: scale(0.93);
}
.otp-verify-footer {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}