
.admin-login{
    color: #fff;
    width: 100%;
    min-height: 100vh;
    background-color: var(--primary-color);
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.admin-login__container{
    
    display: inline-block; /* Enables centering */
  text-align: center;
    width: 30%;
    background-color: var(--secondary-color);
    margin-top: 150px;
}
.admin-login__container__header{
margin-top: 30px;
}
.admin-login__container__body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}

.admin-login__container__body__form-group{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.admin-login__container__body__form-group input{
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-bottom: 1rem;
}
.admin-login__container__body__form-group button{
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-bottom: 1rem;
    background-color: var(--tertiary-color);
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 30px;
}
.admin-login__container__body__form-group button:hover{
    background-color: var(--tertiary-color);
}
.admin-login__container__body__form-group label{
align-self: flex-start;
margin: 10px 0;
}
.forgot-password{
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .admin-login__container{
        width: 80%;
    }
}
    
