.container {
  background-color: var(--primary-color);
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.app {
  color: #fff;
  width: 23%;
  min-height: 100vh;

  background-color: var(--primary-color);
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
button {
  cursor: pointer;
}
.app.admin {
  all: unset;
}
/* Custom styles for toast container */
.Toastify {
  font-family: "Roboto", sans-serif;
}

/* Custom style for success toast */
.Toastify__toast--success {
  background-color: rgb(29, 27, 41);
  /* Change this to your desired background color */
  color: #fff;
}

/* Custom style for error toast */
.Toastify__toast--error {
  background-color: rgb(29, 27, 41);
  color: #fff;
}
.Toastify__close-button {
  color: white;
}

/* Style the close button on hover */
.Toastify__close-button:hover {
  color: lightgray; /* You can adjust this color for hover effect */
}


@media screen and (max-width: 768px) {
  .app {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .app.admin {
    all: unset;
  }
}
@media (min-width: 767px) and (max-width: 1400px) {
  .app {
    width: 50%;
    height: 100%;
    padding: 0;
  }
  .app.admin {
    all: unset;
  }
}
