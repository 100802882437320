/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.share {
  width: 100%;
  background-color: var(--primary-color);
  position: relative;
  min-height: 100vh;
}

.share-code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.qr-code {
  width: 250px;
  height: 250px;
  background: #fff;
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.share-code-content {
  text-align: center;
  margin: 20px 0;

}
.share-copy-link {
  width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 15px;
  gap: 15px;
  padding: 1px 20px;
  border-radius: 25px;
  border: 1px solid #aeaeae33;
  background-color: var(--primary-color);
  color: var(--grey);
}
/* Set the width of the scrollable container */
.share-copy-link p {
    width: 80%;
    overflow-x: scroll;
  }
  
  /* Style the scrollbar */
  .share-copy-link p::-webkit-scrollbar {
   
    height: 1px; /* Set the height of the scrollbar */
  }
  
  /* Track (the area behind the scrollbar) */
  .share-copy-link p::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* You can set any background color you want */
  }
  
  /* Handle (the draggable part of the scrollbar) */
  .share-copy-link p::-webkit-scrollbar-thumb {
    background-color: #888; /* You can set any color you want */
    border-radius: 1px; /* Set the border radius to make it look round */
  }
.share-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}
.share-card-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: 60px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 30px;
  gap: 30px;
  border: 1px solid #aeaeae33;
  border-radius: 10px;
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(174, 174, 174, 0.1)),
      to(rgba(174, 174, 174, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(174, 174, 174, 0.07)), to(rgba(174, 174, 174, 0.07)));
  background: -o-linear-gradient(
      bottom,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    -o-linear-gradient(bottom, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
  background: linear-gradient(
      0deg,
      rgba(174, 174, 174, 0.1),
      rgba(174, 174, 174, 0.1)
    ),
    linear-gradient(0deg, rgba(174, 174, 174, 0.07), rgba(174, 174, 174, 0.07));
    cursor: pointer;
}
.share-copy-link{
  cursor: pointer;
}