.table-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 50px;
  }
  .responsive-table li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: .9rem;
  }
  
  .responsive-table .table-header {
    border-top: 1px solid var(--grey-50, rgba(174, 174, 174, 0.50));
    border-bottom: 1px solid var(--grey-50, rgba(174, 174, 174, 0.50));
    opacity: 0.3;
    background: var(--bg-1, #262835);
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .responsive-table .table-row {
    border-radius: 10px;
    border: 1px solid var(--grey-10, rgba(174, 174, 174, 0.10));
    background: var(--grey-7, rgba(174, 174, 174, 0.07));
  }
  .responsive-table .col-1 {
    flex-basis: 10%;
  }
  .responsive-table .col-2 {
    flex-basis: 20%;
  }
  .responsive-table .col-3 {
    flex-basis:30%;
  }
  .responsive-table .col-4 {
    flex-basis: 40%;
  }
  .table-row .edit{
    color: #6C7A89;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  @media all and (max-width: 767px) {
    .responsive-table .table-header {
      display: none;
    }
    .responsive-table li {
      display: block;
    }
    .responsive-table .col {
      flex-basis: 100%;
    }
    .responsive-table .col {
      display: flex;
      padding: 10px 0;
    }
    .responsive-table .col:before {
      color: #6C7A89;
      padding-right: 10px;
      content: attr(data-label);
      flex-basis: 50%;
      text-align: right;
    }
  }